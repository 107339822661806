/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import "./theme/variables.scss";
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "swiper/scss";

@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Barlow/Barlow-Regular.ttf");
}

@font-face {
  font-family: "Barlow";
  font-weight: bold;
  src: url("./assets/fonts/Barlow/Barlow-Bold.ttf");
}

@font-face {
  font-family: "Barlow";
  font-weight: 600;
  src: url("./assets/fonts/Barlow/Barlow-Medium.ttf");
}

@font-face {
  font-family: "Barlow";
  font-weight: 500;
  src: url("./assets/fonts/Barlow/Barlow-Medium.ttf");
}

@font-face {
  font-family: "Barlow";
  font-weight: 300;
  src: url("./assets/fonts/Barlow/Barlow-Light.ttf");
}

@font-face {
  font-family: "Barlow";
  font-weight: 100;
  src: url("./assets/fonts/Barlow/Barlow-Thin.ttf");
}

@font-face {
  font-family: "Barlow";
  font-weight: 200;
  src: url("./assets/fonts/Barlow/Barlow-Thin.ttf");
}

@font-face {
  font-family: "Barlow";
  font-weight: 700;
  src: url("./assets/fonts/Barlow/Barlow-Black.ttf");
}

@font-face {
  font-family: "Barlow";
  font-weight: 800;
  src: url("./assets/fonts/Barlow/Barlow-Black.ttf");
}

@font-face {
  font-family: "Barlow";
  font-weight: 900;
  src: url("./assets/fonts/Barlow/Barlow-Black.ttf");
}

@font-face {
  font-family: "Barlow";
  font-style: italic;
  src: url("./assets/fonts/Barlow/Barlow-Italic.ttf");
}

@font-face {
  font-family: "Barlow";
  font-style: italic;
  font-weight: bold;
  src: url("./assets/fonts/Barlow/Barlow-BoldItalic.ttf");
}

@font-face {
  font-family: "Barlow";
  font-style: italic;
  font-weight: bolder;
  src: url("./assets/fonts/Barlow/Barlow-BlackItalic.ttf");
}

@font-face {
  font-family: "Barlow";
  font-style: italic;
  font-weight: lighter;
  src: url("./assets/fonts/Barlow/Barlow-LightItalic.ttf");
}

// PLEX - ROBOTO
/* 
@font-face {
  font-family: 'Plex';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Plex';
  font-weight: bold;
  src: url('./assets/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Plex';
  font-weight: 600;
  src: url('./assets/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Plex';
  font-weight: 500;
  src: url('./assets/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Plex';
  font-weight: 300;
  src: url('./assets/fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Plex';
  font-weight: 100;
  src: url('./assets/fonts/Roboto-Thin.ttf');
}

@font-face {
  font-family: 'Plex';
  font-weight: 200;
  src: url('./assets/fonts/Roboto-Thin.ttf');
}

@font-face {
  font-family: 'Plex';
  font-weight: 700;
  src: url('./assets/fonts/Roboto-Black.ttf');
}

@font-face {
  font-family: 'Plex';
  font-weight: 800;
  src: url('./assets/fonts/Roboto-Black.ttf');
}

@font-face {
  font-family: 'Plex';
  font-weight: 900;
  src: url('./assets/fonts/Roboto-Black.ttf');
}

@font-face {
  font-family: 'Plex';
  font-style: italic;
  src: url('./assets/fonts/Roboto-Italic.ttf');
}

@font-face {
  font-family: 'Plex';
  font-style: italic;
  font-weight: bold;
  src: url('./assets/fonts/Roboto-BoldItalic.ttf');
}

@font-face {
  font-family: 'Plex';
  font-style: italic;
  font-weight: bolder;
  src: url('./assets/fonts/Roboto-BlackItalic.ttf');
}

@font-face {
  font-family: 'Plex';
  font-style: italic;
  font-weight: lighter;
  src: url('./assets/fonts/Roboto-LightItalic.ttf');
} */

ion-input {
  --highlight-color-focused: var(--ion-color-tertiary);
  --highlight-color-invalid: var(--ion-color-danger);
}

.gradient-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px; /* Ajuste conforme necessário */
  background: linear-gradient(
    to right,
    var(--ion-color-quaternary),
    var(--ion-color-primary)
  );
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

ion-searchbar {
  --box-shadow: none !important;

  .searchbar-input-container {
    .searchbar-input {
      border-radius: 25px !important;
      background-color: rgba(133, 131, 131, 0.073) !important;
    }
  }
}

:root {
  --ion-text-color: #828585;
  --ion-font-family: "Barlow";
}

.d-flex {
  display: flex;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-auto {
  margin-right: auto;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-sb {
  justify-content: space-between;
}

.justify-content-sa {
  justify-content: space-around;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: end;
}

.b {
  font-weight: 600;
}

.align-items-center {
  align-items: center;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.m-2 {
  margin: 0.75rem !important;
}

.m-3 {
  margin: 1.25rem;
}

.m-4 {
  margin: 1.5rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.75rem !important;
}

.mt-3 {
  margin-top: 1.25rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 1.25rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mr-2 {
  margin-right: 0.75rem;
}

.mr-3 {
  margin-right: 1.25rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 0.5rem;
}

.ml-2 {
  margin-left: 0.75rem;
}

.ml-3 {
  margin-left: 1.25rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.5rem;
}

.p-2 {
  padding: 0.75rem;
}

.p-3 {
  padding: 1.25rem;
}

.p-4 {
  padding: 1.5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pt-2 {
  padding-top: 0.75rem;
}

.pt-3 {
  padding-top: 1.25rem;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.75rem;
}

.pb-3 {
  padding-bottom: 1.25rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 0.5rem;
}

.pr-2 {
  padding-right: 0.75rem;
}

.pr-3 {
  padding-right: 1.25rem;
}

.pr-4 {
  padding-right: 1.5rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 0.5rem;
}

.pl-2 {
  padding-left: 0.75rem;
}

.pl-3 {
  padding-left: 1.25rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}

.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.my-2 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.mx-2 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.py-2 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-2 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.my-3 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mx-3 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.py-3 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-3 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-4 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.text-lighter {
  font-weight: lighter !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.w-75 {
  width: 75%;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}
